<template>
  <div id="mapContainer">
    <div
      class="button-group"
      id="button_group"
      style="top: 15px; bottom: inherit"
    >
      <img
        src="http://a.amap.com/lbs/static/img/markonapp.png"
        style="width: 120px; height: 120px"
      />
      <!-- <div class='button' style='text-align: center'>手机扫码打开demo</div> -->
    </div>
  </div>
</template>
<script>
export default {
  methods: {
        // 首页地区定位
    getLocation() {
      const self = this;
      AMap.plugin("AMap.Geolocation",  () => {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        });

        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete", onComplete);
        AMap.event.addListener(geolocation, "error", onError);

        function onComplete(data) {
          // data是具体的定位信息
          console.log("定位成功信息：", data);
          let reg = /.+?(省|市|自治区|自治州|县|区)/g
          // self.userPosition = data.formattedAddress.match(reg)[0].slice(0,-1)
          self.userPosition = data.formattedAddress.match(reg)[0]
          console.log(self.userPosition)
        }

        function onError(data) {
          // 定位出错
          console.log("定位失败错误：", data);
          // 调用ip定位
          self.getLngLatLocation();
        }
      });
    },
    getLngLatLocation() {
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            console.log("通过ip获取当前城市：", result);
            //逆向地理编码
            AMap.plugin("AMap.Geocoder", function () {
              var geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: result.adcode,
              });

              var lnglat = result.rectangle.split(";")[0].split(",");
              geocoder.getAddress(lnglat, function (status, data) {
                if (status === "complete" && data.info === "OK") {
                  // result为对应的地理位置详细信息
                  console.log(data);
                }
              });
            });
          }
        });
      });
    },
  },
  mounted() {
    let map = new AMap.Map("mapContainer", {
      resizeEnable: true,
      center:[121.26836000000003, 31.10844]
    });
    let marker = new AMap.Marker({
      map: map,
      position: [121.26836000000003, 31.10844],
    });
    // marker.setLabel({
    //   offset: new AMap.Pixel(20, 20), //修改label相对于maker的位置
    //   content: "点击导航至服务处",
    // });
    // marker.on("click", function (e) {
      marker.markOnAMAP({
        name: "上海市松江区",
        position: marker.getPosition(),
      });
    // });
    map.addControl(new AMap.ToolBar());
    if (AMap.UA.mobile) {
      document.getElementById("button_group").style.display = "none";
    }
  },
  created() {
     this.getLocation();
  }
};
</script>
<style scoped>
#mapContainer {
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: 12px;
}
</style>>